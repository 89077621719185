import logo from './logo.svg';
import './App.css';
import LandingPage from './landing_page/LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Politics from './politics/Politics';
import Conditions from './politics/Conditions';
import "./i18n";

function App() {
  return (
    <Router>
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/Politiques" element={<Politics />} />
        <Route path="/Conditions" element={<Conditions />} />
        {/* Fallback route for undefined paths */}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;

import {
    Box,
    Flex,
    IconButton,
    HStack,
    Text,
    Stack,
    useDisclosure,
    useColorMode,
    Container,
    Heading,
    Image,
    useColorModeValue,
    Button,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageChanger from '../language/LanguageChanger';

import logo from './assets/images/logo.png'


export default function Header({ onButtonClick }) {
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const isDark = colorMode === 'dark';
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const downloadRef = useRef(null);
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const Links = [t('Accueil'), t('À propos'), t('Télécharger'), t('Contactez-nous')];
    return (
        <Container maxWidth={"6xl"} px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                {/* Logo */}
                <Flex spacing={8} >
                    {/* <Text fontSize="3xl" fontWeight="semi-bold"  color="white">
                            APA PREMIUM
                        </Text> */}
                    <Image pt={2} width={{base:65,md:70 , lg:90 ,xl:95}} src={logo} />
                </Flex>

                {/* Navigation Links */}

                <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                    {Links.map((link, index) => (
                        <Button onClick={() => onButtonClick(index)} rounded={"20px 0 20px 0"} colorScheme='whiteAlpha' bg={"none"} key={link}>
                            {link}
                        </Button>
                    ))}
                    <LanguageChanger />
                </HStack>
                <Flex display={{ md: 'none' }}>
                    <LanguageChanger />
                    {/* Hamburger Icon Button for Mobile Navigation */}
                    <IconButton
                        colorScheme='blackAlpha'
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Toggle Navigation'}
                        
                        onClick={isOpen ? onClose : onOpen}
                    />
                </Flex>




            </Flex>


            {/* Mobile Menu */}
            {isOpen ? (
                <Box rounded={"lg"} bg={"blackAlpha.300"} pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        {Links.map((link, index) => (
                            <Button onClick={() => onButtonClick(index)} colorScheme='whiteAlpha' bg={"none"} key={link}>
                                {link}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            ) : null}
        </Container>
    );
}

import { Box, Flex, Image, Text, Button, Icon, useToast } from '@chakra-ui/react';
import about from './assets/images/about.png'
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const About = () => {
    const { t } = useTranslation()
    const toast = useToast()
    return (
        <Box id="about" pt={5} >
            <Flex
                alignItems="center"
                flexDirection={{ base: 'column', lg: 'row' }}
                justify="space-between"
                mx="auto"
                maxWidth="1200px"
                px={4}
            >
                {/* About Image */}
                <Box flex="1" mt={{ base: 10, lg: 10 }}>
                    <Flex
                        direction="column"
                        align="center"
                        className="wow fadeInRightBig"
                        data-wow-duration="1.3s"
                        data-wow-delay="0.5s"
                    >
                        <Image
                            height={"550px"}
                            src={about}
                            alt="about"
                            className="image"
                        />

                    </Flex>
                </Box>

                {/* About Content */}
                <Box flex="1" mt={{ base: 5, lg: 45 }} textAlign={{ base: 'center', lg: 'left' }}>
                    <Flex
                        direction="column"
                        className="wow fadeInLeftBig"
                        data-wow-duration="1.3s"
                        data-wow-delay="0.5s"
                    >
                        <Text fontSize="3xl" fontWeight="bold" mb={4}>
                            {t("Découvrez une nouvelle expérience !")}
                        </Text>
                        <Text mb={6}>
                            {t("APA vous connecte à des biens immobiliers uniques, des offres exclusives et un réseau d'agences de confiance. Que vous cherchiez à acheter, louer ou découvrir des opportunités, nous sommes là pour vous guider à chaque étape.")}
                        </Text>

                    </Flex>
                    <Text fontSize="md" fontWeight="bold" color="cyan.600" mb={4}>
                        {t("Téléchargez l'application dès maintenant !")}
                    </Text>

                    <Flex w={"100%"} justifyContent={{ base: 'center', lg: 'left' }} zIndex={999} gap={4} mb={6}>
                        <Box>
                            <Flex
                                as="a"
                                href="/Release/android/APA_PREMIUM_V1.2.6.apk"
                                align="center"
                                download

                                p={3}
                                px={5}
                                borderRadius="lg"
                                bg="black"
                                _hover={{ bg: 'gray.800' }}
                                transition="background-color 0.2s"
                                boxShadow="md"
                            // onClick={(e) => {
                            //     e.preventDefault();  
                            //     toast({
                            //       title: "Bientôt disponible sur le Play Store !",
                            //       position: "top",
                            //       isClosable: true,
                            //     });
                            //   }}
                            >
                                <Icon as={FaGooglePlay} boxSize={6} color="white" />
                                <Box ml={3}>
                                    <Text fontSize="md" fontWeight="bold" color="white">
                                        {/* Play Store */}
                                        Android
                                    </Text>
                                    <Text fontSize="sm" color="whiteAlpha.800">
                                        {t("Télécharger maintenant")}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Flex
                                as="a"
                                align="center"
                                p={3}
                                px={5}
                                cursor={"pointer"}
                                borderRadius="lg"
                                bg="black"
                                _hover={{ bg: 'gray.800' }}
                                transition="background-color 0.2s"
                                boxShadow="md"
                                onClick={() => window.open("https://apps.apple.com/ma/app/apa-premium/id6480427570?l=fr-FR", '_blank')}
                            >
                                <Icon as={FaApple} boxSize={6} color="white" />
                                <Box ml={3}>
                                    <Text fontSize="md" fontWeight="bold" color="white">
                                        App Store
                                    </Text>
                                    <Text fontSize="sm" color="whiteAlpha.800">
                                        {t("Télécharger maintenant")}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>

            </Flex>
        </Box>
    );
};

export default About;

import React, { useEffect } from 'react'
import Footer from '../landing_page/Footer'
import Header from './Header'
import header_shape from '../landing_page/assets/images/header_shape.svg';
import {
    Container,
    Box,
    Heading,
    Text,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function Conditions() {
    const {t}=useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);
    return (
        <Box bg="gray.50">
            <Box bgRepeat={"no-repeat"} bgImage={header_shape} position="relative" w={"100hw"} h={"fit-content"}>
                <Header  onButtonClick={() => {navigate('/')}}/>
            </Box>

            <Container maxW="4xl" py={8}>
                {/* Page Title */}
                <Box textAlign="center" mb={8}>
                    <Heading size="xl">Conditions Générales</Heading>
                    <Text mt={2} fontSize="sm" color="gray.600">
                        Dernière mise à jour : 08 Novembre 2024
                    </Text>
                </Box>

                {/* Introduction */}
                <Box mb={8}>
                    <Text>
                        Ces Conditions Générales régissent l’utilisation de notre service. En accédant à notre application, vous acceptez d'être lié par ces conditions. Si vous n’acceptez pas ces termes, veuillez ne pas utiliser notre service. Nous nous réservons le droit de mettre à jour ou de modifier ces conditions à tout moment. Il est donc important que vous les consultiez régulièrement pour rester informé de toute modification.
                    </Text>
                </Box>
                <Divider />

                {/* Accordion Sections */}
                <Accordion allowToggle allowMultiple defaultIndex={[0, 1, 2, 3, 4, 5, 6]}>

                    {/* Acceptation des Conditions */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Acceptation des Conditions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                En accédant ou en utilisant nos services, vous acceptez d’être lié par les termes et conditions de cette page. Ces conditions s’appliquent à tous les utilisateurs de l’application, y compris, mais sans s’y limiter, les utilisateurs qui sont des visiteurs, des clients, des partenaires, ou des contributeurs de contenu. Vous acceptez également que nous puissions modifier ces conditions à tout moment, sans préavis. En continuant d’utiliser le service après la publication des modifications, vous acceptez les termes révisés.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Utilisation du Service */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Utilisation du Service
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                L’utilisation de notre service est destinée uniquement à des fins légales et conformes aux lois en vigueur. Vous vous engagez à ne pas utiliser le service à des fins frauduleuses, illégales ou nuisibles. En tant qu’utilisateur, vous êtes responsable de la confidentialité de vos informations de connexion et de toutes les activités sur votre compte. Vous acceptez également de ne pas perturber ou interférer avec le bon fonctionnement du service ou accéder de manière non autorisée à d'autres systèmes informatiques liés au service.
                            </Text>
                            <Text mt={4}>
                                Nous nous réservons le droit de suspendre ou de résilier l’accès au service pour tout comportement qui enfreint ces conditions. Nous déclinons toute responsabilité quant aux erreurs, omissions ou interruptions du service et nous ne serons pas responsables des pertes de données ou autres dommages découlant de l’utilisation du service.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Propriété Intellectuelle */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Propriété Intellectuelle
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Tous les droits de propriété intellectuelle concernant le contenu de notre service, y compris, mais sans s’y limiter, les textes, graphiques, logos, images, vidéos et autres supports multimédias, sont la propriété exclusive de l’entreprise ou de ses concédants de licence. Vous vous engagez à ne pas reproduire, distribuer, modifier, ou créer des œuvres dérivées du contenu de notre service sans autorisation expresse préalable.
                            </Text>
                            <Text mt={4}>
                                Les marques de commerce, les logos et autres symboles présents sur notre service sont également protégés par des droits de propriété intellectuelle. Toute utilisation non autorisée de ces éléments peut constituer une violation des lois sur les marques et le droit d'auteur.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Modifications des Conditions */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Modifications des Conditions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications prendront effet dès leur publication sur cette page. Il est de votre responsabilité de consulter régulièrement cette page pour prendre connaissance des changements éventuels. En continuant d’utiliser le service après la publication des modifications, vous acceptez les nouvelles conditions.
                            </Text>
                            <Text mt={4}>
                                Nous vous conseillons de vérifier fréquemment cette page pour vous assurer que vous êtes d’accord avec les dernières conditions. Si vous n’êtes pas d’accord avec les nouvelles conditions, vous devez cesser d’utiliser le service.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Responsabilité */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Responsabilité
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous ne serons en aucun cas responsables des pertes, dommages directs ou indirects, ou des frais encourus à la suite de l’utilisation ou de l’incapacité d’utiliser notre service. Ceci inclut, sans limitation, toute perte de données, interruption du service, ou autres problèmes techniques. Vous reconnaissez et acceptez que vous utilisez notre service à vos propres risques et que nous ne garantissons pas l'absence d'erreurs ou d'interruptions.
                            </Text>
                            <Text mt={4}>
                                De plus, nous déclinons toute responsabilité quant aux actions ou omissions des utilisateurs du service, y compris, sans s’y limiter, toute fraude, malveillance, ou dommage causé par l’utilisation du service. Vous êtes entièrement responsable de votre utilisation du service et des conséquences en découlant.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Loi Applicable */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Loi Applicable
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Ces conditions sont régies par les lois en vigueur en France. En cas de conflit ou de différend découlant de ces conditions, les parties conviennent que la juridiction compétente sera celle des tribunaux français, et ce, sans égard aux principes de conflits de lois. Vous acceptez que toute action légale ou procédure judiciaire liée à ces conditions soit soumise à la juridiction exclusive des tribunaux français.
                            </Text>
                            <Text mt={4}>
                                En utilisant notre service, vous acceptez d’être soumis à la compétence exclusive des tribunaux français pour résoudre toute réclamation découlant de l’utilisation du service.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>

                
            </Container>

            <Footer />

        </Box>

    )
}

export default Conditions
import React, { useEffect } from 'react'
import Footer from '../landing_page/Footer'
import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import header_shape from '../landing_page/assets/images/header_shape.svg';
import {
    Container,
    Heading,
    Text,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import Header from '../landing_page/Header';
import { useNavigate } from 'react-router-dom';
function Politics() {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);
    return (
        <Box bg="gray.50">
            <Box bgRepeat={"no-repeat"} bgImage={header_shape} position="relative" w={"100hw"} h={"fit-content"}>
                <Header  onButtonClick={() => {navigate('/')}}/>
            </Box>

            <Container maxW="4xl" py={8}>
                {/* Titre de la page */}
                <Box textAlign="center" mb={8}>
                    <Heading size="xl">Politique de Confidentialité</Heading>
                    <Text mt={2} fontSize="sm" color="gray.600">
                        Dernière mise à jour : 08 Novembre 2024
                    </Text>
                </Box>

                {/* Introduction */}
                <Box mb={8}>
                    <Text>
                        Cette Politique de Confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le Service et vous informe de vos droits en matière de confidentialité ainsi que de la manière dont la loi vous protège.
                    </Text>
                    <Text mt={4}>
                        En utilisant le Service, vous acceptez la collecte et l'utilisation des informations conformément à cette Politique de Confidentialité.
                    </Text>
                </Box>
                <Divider />

                {/* Sections Accordéon */}
                <Accordion allowToggle allowMultiple defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                    {/* Interprétation et Définitions */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" as='span' flex="1" textAlign="left">
                                    Interprétation et Définitions
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Les mots dont la première lettre est en majuscule ont des significations définies dans les conditions suivantes. Ces définitions auront le même sens qu'elles apparaissent au singulier ou au pluriel.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Collecte et Utilisation de Vos Données Personnelles */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" as='span' flex="1" textAlign="left">
                                    Collecte et Utilisation de Vos Données Personnelles
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text size="md" fontWeight={"semibold"} mb={2}>Types de Données Collectées :</Text>
                            <UnorderedList>
                                <ListItem>
                                    <Text fontWeight="bold">Données Personnelles</Text>
                                    <Text>
                                        Lors de l'utilisation de notre Service, nous pouvons vous demander de fournir certaines informations personnelles, telles que : adresse e-mail, prénom et nom, numéro de téléphone, adresse, état, province, code postal et ville.
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    <Text mt={4} fontWeight="bold">Données d'Utilisation</Text>
                                    <Text mt={4}>
                                        Les données d'utilisation sont collectées automatiquement lors de l'utilisation du Service.                                    </Text>
                                    <Text mt={4}>
                                        Les données d'utilisation peuvent inclure des informations telles que l'adresse IP (par exemple, l'adresse IP) de votre appareil, le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.                                    </Text>
                                    <Text mt={4}>
                                        Lorsque vous accédez au Service par ou via un appareil mobile, nous pouvons collecter automatiquement certaines informations, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.                                    </Text>
                                    <Text mt={4}>
                                        Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service par ou via un appareil mobile.                                    </Text>

                                </ListItem>

                            </UnorderedList>

                            {/* <ul style={{ marginLeft: 40 }}>
                                <ListItem>
                                   
                                </ListItem>

                                <ListItem>
                                    </ListItem>
                            </ul> */}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Informations provenant des services tiers de médias sociaux
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                La société vous permet de créer un compte et de vous connecter à notre service via les services tiers suivants de médias sociaux :
                            </Text>
                            <UnorderedList mt={1}>
                                <ListItem>Google</ListItem>
                                <ListItem>Facebook</ListItem>
                            </UnorderedList>
                            <Text mt={4}>
                                Si vous décidez de vous inscrire via ou d'accorder l'accès à un service tiers de médias sociaux, nous pouvons collecter des données personnelles déjà associées à votre compte du service tiers de médias sociaux, telles que votre nom, votre adresse e-mail, vos activités ou votre liste de contacts associée à ce compte.
                            </Text>
                            <Text mt={4}>
                                Vous pouvez également avoir la possibilité de partager des informations supplémentaires avec la société via votre compte du service tiers de médias sociaux. Si vous choisissez de fournir de telles informations et des données personnelles, lors de l'enregistrement ou autrement, vous donnez à la société la permission de les utiliser, de les partager et de les stocker conformément à cette politique de confidentialité.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Technologies de suivi et cookies */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Technologies de suivi et cookies
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre service et stocker certaines informations. Les technologies de suivi utilisées sont des balises, des balises pixel, et des scripts pour collecter et suivre des informations et améliorer et analyser notre service. Les technologies que nous utilisons peuvent inclure :
                            </Text>
                            <UnorderedList mt={1}>
                                <ListItem><strong>Cookies ou cookies de navigateur</strong> : Un cookie est un petit fichier placé sur votre appareil. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour indiquer quand un cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre service. À moins que vous n'ayez modifié les paramètres de votre navigateur pour refuser les cookies, notre service peut utiliser des cookies.</ListItem>
                                <ListItem><strong>Balises Web</strong> : Certaines sections de notre service et de nos e-mails peuvent contenir de petits fichiers électroniques appelés balises Web (également appelées gifs transparents, balises pixel et gifs à un pixel) qui permettent à la société, par exemple, de compter les utilisateurs ayant visité ces pages ou ouvert un e-mail, ainsi que pour d'autres statistiques de site Web (par exemple, enregistrer la popularité d'une certaine section et vérifier l'intégrité du système et du serveur).</ListItem>
                            </UnorderedList>
                            <Text mt={4}>
                                Les cookies peuvent être des cookies “persistants” ou “de session”. Les cookies persistants restent sur votre ordinateur ou appareil mobile lorsque vous êtes hors ligne, tandis que les cookies de session sont supprimés dès que vous fermez votre navigateur Web.
                            </Text>

                            <Text mt={4} fontWeight="bold">Nous utilisons à la fois des cookies de session et des cookies persistants pour les objectifs ci-dessous :</Text>
                            <UnorderedList mt={4}>
                                <ListItem><strong>Cookies nécessaires / essentiels</strong>
                                    <UnorderedList spacing={2} ml={10} styleType="'- '">
                                        <ListItem>Type : Cookies de session</ListItem>
                                        <ListItem>Administé par : Nous</ListItem>
                                        <ListItem>But : Ces cookies sont essentiels pour vous fournir les services disponibles sur le site Web et vous permettre d'utiliser certaines de ses fonctionnalités. Ils aident à authentifier les utilisateurs et à prévenir l'utilisation frauduleuse des comptes utilisateurs. Sans ces cookies, les services que vous avez demandés ne peuvent pas être fournis, et nous n'utilisons ces cookies que pour vous fournir ces services.</ListItem>
                                    </UnorderedList>
                                </ListItem>
                                <ListItem mt={4}><strong>Cookies de politique de cookies / Acceptation de la notice</strong>
                                    <UnorderedList spacing={2} ml={10} styleType="'- '">
                                        <ListItem>Type : Cookies persistants</ListItem>
                                        <ListItem>Administé par : Nous</ListItem>
                                        <ListItem>But : Ces cookies identifient si les utilisateurs ont accepté l'utilisation des cookies sur le site Web.</ListItem>
                                    </UnorderedList>
                                </ListItem>
                                <ListItem mt={4}><strong>Cookies de fonctionnalité</strong>
                                    <UnorderedList spacing={2} ml={10} styleType="'- '">
                                        <ListItem>Type : Cookies persistants</ListItem>
                                        <ListItem>Administé par : Nous</ListItem>
                                        <ListItem>But : Ces cookies nous permettent de nous souvenir de vos choix lorsque vous utilisez le site Web, comme mémoriser vos informations de connexion ou votre préférence de langue. L'objectif de ces cookies est de vous fournir une expérience plus personnalisée et d'éviter que vous n'ayez à saisir à nouveau vos préférences chaque fois que vous utilisez le site Web.</ListItem>
                                    </UnorderedList>
                                </ListItem>
                            </UnorderedList>
                            <Text mt={4}>
                                Pour plus d'informations sur les cookies que nous utilisons et vos choix concernant les cookies, veuillez consulter notre politique en matière de cookies ou la section cookies de notre politique de confidentialité.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                    {/* Utilisation de Vos Données Personnelles */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Utilisation de Vos Données Personnelles
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                La Société peut utiliser les Données Personnelles pour fournir et maintenir le Service, gérer votre compte, exécuter des contrats, vous contacter, ainsi que pour d'autres finalités commerciales.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Technologies de Suivi et Cookies */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Technologies de Suivi et Cookies
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre Service et stocker certaines informations pour améliorer et analyser notre Service. Vous pouvez configurer votre navigateur pour refuser les cookies, mais certaines parties du Service pourraient ne pas fonctionner correctement.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Partage de Vos Données Personnelles */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Partage de Vos Données Personnelles
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous pouvons partager vos informations avec des prestataires de services, des affiliés, des partenaires commerciaux ou lors de transferts d'entreprise. Les Données Personnelles peuvent également être divulguées pour des raisons légales.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Conservation et Suppression de Vos Données */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Conservation et Suppression de Vos Données
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                La Société conservera vos Données Personnelles aussi longtemps que nécessaire pour les finalités décrites dans cette politique. Vous pouvez demander la suppression de vos données à tout moment.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>

                    {/* Sécurité de Vos Données */}
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box fontWeight={"semibold"} fontSize="xl" flex="1" textAlign="left">
                                    Sécurité de Vos Données
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Text>
                                Nous prenons des mesures raisonnables pour sécuriser vos Données Personnelles, mais sachez qu'aucune méthode de transmission sur Internet n'est totalement sécurisée.
                            </Text>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>


            </Container>

            <Footer />

        </Box>

    )
}

export default Politics
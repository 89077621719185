'use client'

import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Icon,
    IconButton,
    createIcon,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react'
import { FaApple, FaGooglePlay } from 'react-icons/fa'
import header_app from './assets/images/header_app.png'
import FeaturesSection from './FeaturesSection'
import { useTranslation } from 'react-i18next'
export default function HeaderContent({ headerContentRef, featuresRef }) {
    const { t } = useTranslation()
    const toast = useToast()
    return (
        <Container ref={headerContentRef} maxW="6xl">
            <Stack
                align="center"
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 10 }}
                direction={{ base: 'column', md: 'row' }}
            >
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}

                    >
                        <Text
                            fontSize={{ base: '3xl', sm: '4xl', lg: '3xl' }}
                            color={"white"}
                            as="span"
                            position="relative"
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.400',
                                zIndex: -1,
                            }}
                        >
                            {t("Explorez l'exception!")}
                        </Text>
                        <br />
                        <Text fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }} as="span" color="cyan.500">
                            {t("Profitez des offres APA.")}
                        </Text>
                    </Heading>
                    <Text color="white">
                        {t("APA, l’application incontournable pour l’achat, la location, et les offres exclusives sur les biens immobiliers. Explorez, économisez, et accédez aux meilleurs biens et opportunités du marché.")}

                    </Text>
                    <Flex zIndex={999} gap={4}>
                        <Box>
                            <Flex
                                as="a"
                                href="/Release/android/APA_PREMIUM_V1.2.6.apk"
                                align="center"
                                download
                                p={3}
                                px={5}
                                borderRadius="lg"
                                bg="black"
                                _hover={{ bg: 'gray.800' }}
                                transition="background-color 0.2s"
                                boxShadow="md"
                            // onClick={(e) => {
                            //     e.preventDefault();  
                            //     toast({
                            //       title: "Bientôt disponible sur le Play Store !",
                            //       position: "top",
                            //       isClosable: true,
                            //     });
                            //   }}
                            >
                                <Icon as={FaGooglePlay} boxSize={6} color="white" />
                                <Box ml={3}>
                                    <Text fontSize="md" fontWeight="bold" color="white">
                                        {/* Play Store */}
                                        Android
                                    </Text>
                                    <Text fontSize="sm" color="whiteAlpha.800">
                                        {t("Télécharger maintenant")}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Flex
                                as="a"
                                cursor={"pointer"}
                                align="center"
                                p={3}
                                px={5}
                                borderRadius="lg"
                                bg="black"
                                _hover={{ bg: 'gray.800' }}
                                transition="background-color 0.2s"
                                boxShadow="md"
                                onClick={() => window.open("https://apps.apple.com/ma/app/apa-premium/id6480427570?l=fr-FR", '_blank')}
                            >
                                <Icon as={FaApple} boxSize={6} color="white" />
                                <Box ml={3}>
                                    <Text fontSize="md" fontWeight="bold" color="white">
                                        App Store
                                    </Text>
                                    <Text fontSize="sm" color="whiteAlpha.800">
                                        {t("Télécharger maintenant")}
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Stack>
                <Flex flex={1} justify="center" align="center" position="relative" w="full">
                    {/* <Blob
                        w="150%"
                        h="150%"
                        position="absolute"
                        top="-20%"
                        left={-20}

                        zIndex={1}
                        color={useColorModeValue('blue.50', 'cyan.900')}
                    /> */}
                    <Image
                        alt="Hero Image"
                        fit="cover"
                        align="center"
                        w="100%"
                        h="100%"
                        src={header_app}
                    />
                </Flex>
            </Stack>
            <Box ref={featuresRef}>
                <FeaturesSection />
            </Box>
        </Container>
    )
}


import {
    Box,
    Container,
    SimpleGrid,
    VStack,
    Heading,
    Text,
    Icon
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BiSolidCalendarEvent, BiSolidDiscount } from 'react-icons/bi';
import { BsBuildingsFill } from 'react-icons/bs';
import { FaCode, FaLayerGroup, FaGift } from 'react-icons/fa';
import { MdDiscount } from 'react-icons/md';
import { TbStarsFilled } from "react-icons/tb";



const FeaturesSection = () => {
    const {t}=useTranslation()
    const featuresData = [
        {
            icon: TbStarsFilled,
            title: "APA Premium",
            description: t("Accès exclusif aux partenaires APA avec des réductions spéciales."),
            delay: 0.2
        },
        {
            icon: BsBuildingsFill,
            title: "APA Agency",
            description: t("Achetez ou louez des biens immobiliers avec APA, en toute confiance."),
            delay: 0.5
        },
        {
            icon: MdDiscount,
            title: "APA Promotions",
            description: t("Consultez nos dernières promotions pour rester informé des offres spéciales et exclusives."),
            delay: 0.9
        },
        {
            icon: BiSolidCalendarEvent,
            title: "APA Events",
            description: t("Restez informé des événements organisés par APA."),
            delay: 0.9
        },
    ];
    return (
        <Box as="section" pt="35px" pb="80px" id="features" >
            <Container maxW="container.lg">
                <SimpleGrid columns={{ base: 1,sm:2,md:3, lg: 4 }} spacing={10} justifyContent="center">
                    {featuresData.map((feature, index) => (
                        <VStack
                            key={index}
                            spacing={3}
                            p={6}
                            bg="white"
                            rounded="md"
                            shadow="md"
                            align="center"
                            textAlign="center"
                            transition="all 0.3s"
                            _hover={{ transform: "scale(1.05)" }}
                        >
                            <Icon as={feature.icon} boxSize={10} color="cyan.500" />
                            <Heading as="h4" size="md" mt={4}>
                                {feature.title}
                            </Heading>
                            <Text color="gray.600">{feature.description}</Text>
                        </VStack>
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default FeaturesSection;

import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    IconButton,
    Link,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
    useColorMode,
    useColorModeValue,
    Image,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { ArrowForwardIcon } from '@chakra-ui/icons';

// Import the Header component
import Header from './Header';  // Adjust this path based on your file structure
import DownloadSection from './DownloadSection';
import HeaderContent from './HeaderContent';
import FeaturesSection from './FeaturesSection';
import About from './About';
import Footer from './Footer';
import header_shape from './assets/images/header_shape.svg';
import footer_shape from './assets/images/footer_shape.svg';
import dots from './assets/images/dots.svg';
import shape1 from './assets/images/shape-1.svg';
import shape2 from './assets/images/shape-2.svg';
import shape3 from './assets/images/shape-3.svg';
import { useRef } from 'react';
import ContactUs from './ContactUs';

const LandingPage = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const isDark = colorMode === 'dark';
    const headerRef = useRef(null);
    const headerContentRef = useRef(null);
    const featuresSectionRef = useRef(null);
    const contactUsRef = useRef(null);
    const aboutRef = useRef(null);
    // Function to scroll to a section
    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            // console.error("Ref is not defined: ", ref);
        }
    };
    return (
        <Box bg="gray.50">
            <Box bgRepeat={"no-repeat"} bgImage={header_shape} position="relative" w={"100hw"} minH="100vh">
                {/* Background Shapes */}
                <Image src={shape1} position="absolute" top="0" left="0" boxSize="250px" opacity={0.7} />
                <Image src={shape2} position="absolute" top="10%" right="5%" boxSize="300px" opacity={0.7} />
                <Image src={shape3} position="absolute" bottom="20%" left="-20" boxSize="350px" opacity={0.7} />

                <Header
                    onButtonClick={(index) => {
                        if (index === 0) scrollToSection(headerContentRef); // Scroll to Header
                        if (index === 1) scrollToSection(featuresSectionRef); // Scroll to HeaderContent
                        if (index === 2) scrollToSection(aboutRef); // Scroll to FeaturesSection
                        if (index === 3) scrollToSection(contactUsRef); // Scroll to FeaturesSection
                    }}
                />

                {/* Content sections with refs */}
                <Box ref={headerContentRef}>
                    <HeaderContent featuresRef={featuresSectionRef} /> {/* Pass featuresRef */}
                </Box>


                <Box ref={aboutRef}>
                    <About />
                </Box>
                {/* <Box>
                    <ContactUs/>
                </Box> */}

                <Box ref={contactUsRef}>
                    <Flex
                        alignItems="center"
                        flexDirection={{ base: 'column', lg: 'row' }}
                        justify="space-between"
                        mx="auto"
                        maxWidth="1200px"
                        px={4}
                    >
                        <Box h={"auto"} w="100%">
                            <ContactUs  />
                        </Box>
                    </Flex>

                </Box>

            </Box>

            {/* <DownloadSection/> */}
            {/* Hero Section */}

            <Footer />
            {/* <Box  bgRepeat={"no-repeat"} bgImage={footer_shape} right={0} bottom={0} position="relative" w={"100hw"} h="500px">
</Box> */}
        </Box>
    );
};

export default LandingPage;
